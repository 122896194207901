<template>
  <div
    style="height: inherit"
    class="compose-box"
  >

    <!-- Ticket Header -->
    <div class="email-app-list">
      <div class="email-detail-header mb-1">

        <!-- Header: Left -->
        <div class="email-header-left d-flex align-items-center">
          <span class="go-back mr-1">
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="align-bottom"
              @click="goBack"
            />
          </span>
          <h4 class="email-subject mb-0">
            Create New Ticket
          </h4>
        </div>
      </div>

      <!-- Ticket Details -->
      <vue-perfect-scrollbar
        id="response-box"
        :settings="perfectScrollbarSettings"
        class="scroll-area"
      >
        <!-- Body -->
        <b-form class="w-100 p-2">
          <b-row class="p-1 bg-white">
            <b-col cols="6">
              <!-- Field: To -->
              <div class="compose-mail-form-field mb-1">
                <label
                  for="ticket-name"
                  class="form-label"
                >Name: </label>
                <b-form-input
                  id="ticket-name"
                  v-model="composeData.name"
                />
              </div>
            </b-col>
            <b-col cols="6">
              <!-- Field: Cc (Hidden Initially) -->
              <div
                class="compose-mail-form-field mb-1"
              >
                <label
                  for="ticket-email"
                  class="form-label"
                >Email: </label>
                <b-form-input
                  id="ticket-email"
                  v-model="composeData.email"
                />
              </div>
            </b-col>
            <b-col cols="6">
              <div
                class="compose-mail-form-field mb-1"
              >
                <label
                  for="ticket-company"
                  class="form-label"
                >Company: </label>
                <b-form-input
                  id="ticket-company"
                  v-model="composeData.company_name"
                />
              </div>
            </b-col>
            <b-col cols="6">
              <div
                class="compose-mail-form-field mb-1"
              >
                <label
                  for="ticket-phonenumber"
                  class="form-label"
                >Phone: </label>
                <b-form-input
                  id="ticket-phonenumber"
                  v-model="composeData.phonenumber"
                />
              </div>
            </b-col>
            <b-col cols="4">
              <div
                class="compose-mail-form-field mb-1"
              >
                <label
                  for="ticket-channel"
                  class="form-label"
                >Channel: </label>
                <v-select
                  id="ticket-channel"
                  v-model="composeData.channel_id"
                  :options="reference.channeledit"
                  class="flex-grow-1 ticket-to-selector"
                  :reduce="val => val.channel_id"
                  :selectable="(option) => !option.name.includes('Email')"
                  label="name"
                />
              </div>
            </b-col>
            <b-col cols="4">
              <div
                class="compose-mail-form-field mb-1"
              >
                <label
                  for="ticket-priority"
                  class="form-label"
                >Priority: </label>
                <v-select
                  id="ticket-priority"
                  v-model="composeData.priority_id"
                  :options="reference.priority"
                  class="flex-grow-1 ticket-to-selector"
                  :reduce="val => val.priority_id"
                  label="name"
                />
              </div>
            </b-col>
            <b-col cols="4">
              <div
                class="compose-mail-form-field mb-1"
              >
                <label
                  for="ticket-status"
                  class="form-label"
                >Status: </label>
                <v-select
                  id="ticket-status"
                  v-model="composeData.status_id"
                  :options="ticketstatus"
                  class="flex-grow-1 ticket-to-selector"
                  :reduce="val => val.status_id"
                  label="name"
                />
              </div>
            </b-col>
            <b-col cols="12">
              <!-- Field: Subject -->
              <div class="compose-mail-form-field mb-1">
                <label
                  for="ticket-subject"
                  class="form-label"
                >Subject: </label>
                <b-form-input
                  id="ticket-subject"
                  v-model="composeData.subject"
                />
              </div>
            </b-col>
            <b-col cols="12">
              <!-- Field: Message - Quill Editor -->
              <div class="message-editor mb-1">
                <label
                  for="ticket-subject"
                  class="form-label"
                >Customer Problems: </label>
                <quill-editor
                  id="quil-content"
                  v-model="composeData.message"
                  :options="editorOption"
                />
                <div
                  id="quill-toolbar"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                </div>
              </div>

              <!-- Field: Response - Quill Editor -->
              <div
                v-if="composeData.status_id === 5 || composeData.status_id === 2"
                class="message-editor"
              >
                <label
                  for="ticket-subject"
                  class="form-label"
                >Agent Response: </label>
                <quill-editor
                  id="quil-content2"
                  v-model="composeData.response"
                  :options="editorOption2"
                />
                <div
                  id="quill-toolbar2"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                </div>
              </div>

              <div
                v-if="attach"
                class="p-1"
              >
                <b-form-file
                  v-model="files"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  size="sm"
                  multiple
                  @input="checkFile"
                />
              </div>

              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  split
                  variant="primary"
                  size="sm"
                  right
                  @click="sendEmail"
                >
                  Save
                </b-button>
                <b-button
                  class="ml-1"
                  variant="flat-info"
                  @click.prevent="viewAttach"
                >
                  <feather-icon
                    icon="PaperclipIcon"
                    size="14"
                    class="cursor-pointer"
                  />
                </b-button>
              </div>

              <template v-if="alert">
                <b-alert
                  :variant="alertDetail.variant"
                  class="mx-1"
                  dismissible
                  show
                >
                  <h4 class="alert-heading">
                    {{ alertDetail.title }}
                  </h4>
                  <div
                    v-if="alertDetail.variant === 'danger' && alertDetail.errors !== ''"
                    class="alert-body"
                  >
                    <ul>
                      <li
                        v-for="(item, index) in alertDetail.errors"
                        :key="index"
                      >
                        {{ `${index}: ${item}` }}
                      </li>
                    </ul>
                  </div>
                  <div
                    v-else
                    class="alert-body"
                  >
                    {{ alertDetail.text }}
                  </div>
                </b-alert>
              </template>

            </b-col>
          </b-row>

        </b-form>
      </vue-perfect-scrollbar>

    </div>
  </div>
</template>

<script>
import {
  BForm, BFormInput, BRow, BCol, BButton,
  BAlert, VBTooltip, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import { quillEditor } from 'vue-quill-editor'
import moment from 'moment-business-time'
// eslint-disable-next-line no-unused-vars
import * as Emoji from 'quill-emoji'
import { required, email } from '@validations'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import useEmail from './useEmail'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  components: {

    // BSV
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormFile,
    BAlert,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,
    quillEditor,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const toast = useToast()
    const composeData = ref({
      name: '',
      email: '',
      phonenumber: '',
      company_name: '',
      channel_id: '',
      subject: '',
      message: '',
    })
    const { resolveLabelColor } = useEmail()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Customer Detailed Problems',
    }

    const editorOption2 = {
      modules: {
        toolbar: '#quill-toolbar2',
      },
      placeholder: 'Agent Responses',
    }

    const userData = getUserData()

    return {
      toast,
      userData,
      composeData,
      editorOption,
      editorOption2,

      refFormObserver,
      getValidationState,

      // UI
      perfectScrollbarSettings,

      // useTicket
      resolveLabelColor,
    }
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      today,
      required,
      email,
      goto: '/tickets',
      ticketnumber: '',
      reference: {},
      holidays: [],
      ticketData: '',
      ticketstatus: [
        { status_id: 2, name: 'Open Ticket' },
        { status_id: 5, name: 'Closed' },
        { status_id: 6, name: 'Spam' },
      ],
      files: [],
      attach: false,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  created() {
    this.composeData.phonenumber = this.$route.query.number
    if (this.$route.query.number) {
      this.composeData.channel_id = 4
    }
    this.getReference()
  },
  methods: {
    goBack() {
      this.$router.push(this.goto)
    },
    getReference() {
      this.$http.post('ref/tx', true, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            this.reference = resp.data

            const channels = resp.data.channel
            const channel = []

            channels.map(data => {
              if (data.name !== 'Email' && data.name !== 'Whatsapp' && data.name !== 'Contact Form') {
                channel.push(data)
              }
              return data
            })
            this.reference.channeledit = channel

            const liburAll = this.reference.holidays
            const libur = []

            Object.keys(liburAll).map(data => {
              if (data !== 'created-at') {
                const tgl = moment(data).format('YYYY-MM-DD')
                libur.push(tgl)
              }
              return data
            })
            this.holidays = libur
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Refences',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    viewAttach() {
      this.attach = (this.attach === false)
    },
    checkFile(files) {
      this.alert = false
      files.map((val, key) => {
        if (val.type !== 'image/jpeg' && val.type !== 'image/png' && val.type !== 'application/pdf') {
          this.files.splice(key, 1)
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            errors: { file: `"${val.name}" extension invalid. only jpeg, png, and pdf allowed` },
          }
        }
        return true
      })
    },
    sendEmail() {
      document.getElementById('loading-bg').style.display = 'block'

      if (this.composeData.email === '') {
        delete this.composeData.email
      }

      this.$http.post('/ticket', this.composeData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            this.ticketData = resp.data
            const status = true

            const promise2 = new Promise(resolve => {
              if (this.files.length !== 0) {
                for (let i = 0; i < this.files.length; i += 1) {
                  const formFile = new FormData()
                  formFile.append('ticket_id', resp.data.ticket_id)
                  formFile.append('attachment', this.files[i])

                  this.$http.post('/ticket/attach', formFile, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                    },
                  })
                    .then(fresp => {
                      if (fresp.data.success === true) {
                        resolve(true)
                      } else {
                        document.getElementById('loading-bg').style.display = 'none'

                        this.alert = true
                        this.alertDetail = {
                          variant: 'danger',
                          title: 'Error Found',
                          text: (fresp.response.data.errors !== '') ? fresp.response.data.errors : '',
                        }
                        resolve(false)
                      }
                    })
                    .catch(error => {
                      document.getElementById('loading-bg').style.display = 'none'

                      this.alert = true
                      this.alertDetail = {
                        variant: 'danger',
                        title: 'Error Found',
                        text: (error.response.data.errors !== '') ? error.response.data.errors : error,
                      }
                      resolve(false)
                    })
                }
              } else {
                resolve(true)
              }
            })

            Promise.all([promise2, status]).then(values => {
              if (values[0] && values[1]) {
                document.getElementById('loading-bg').style.display = 'none'
                this.alert = false
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'New Ticket Created',
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
                if (this.ticketData.status_id !== 6) {
                  this.$router.push({ name: 'tickets-view', params: { id: this.ticketData.ticket_id } })
                }
                this.discardEmail()
              }
            })
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Ticket',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.message !== '') ? error.response.data.message : error,
            errors: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
